// src/components/DraggableList.tsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import axios from 'axios';
import { FileWithPreview } from '../pages/FileUploader';
import './DraggableList.css';

interface DraggableListProps {
  files: FileWithPreview[];
  onReorder: (files: FileWithPreview[]) => void;
  handleRemoveFile: (id: string) => void;
  handleUpdateFile: (updatedFile: FileWithPreview) => void;
  api: string;
  clientId: string;
}

interface EditingContentState {
  fileId: string;
  content: string;
  editable: boolean;
  fileType: 'pdf' | 'text';
}

const DraggableList: React.FC<DraggableListProps> = ({
  files,
  onReorder,
  handleRemoveFile,
  handleUpdateFile,
  api,
  clientId,
}) => {
  // 로컬 상태로 files를 관리하여 즉시 UI에 반영되도록 함.
  const [localFiles, setLocalFiles] = useState<FileWithPreview[]>(files);
  useEffect(() => {
    setLocalFiles(files);
  }, [files]);

  // 파일 제목 편집을 위한 로컬 상태
  const [editingTitle, setEditingTitle] = useState<{ fileId: string; title: string } | null>(null);
  
  // 파일 내용 편집 또는 미리보기 상태
  const [editingContent, setEditingContent] = useState<EditingContentState | null>(null);
  // 각 파일의 description 입력값을 위한 로컬 상태
  const [descValues, setDescValues] = useState<Record<string, string>>({});
  // 각 파일에 대한 디바운스 타이머 저장
  const timers = useRef<Record<string, NodeJS.Timeout>>({});

  // Drag & Drop 순서 변경 처리 (priority 업데이트)
  const handleDragEnd = useCallback((result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    
    const reorderedItems = Array.from(localFiles);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);
    
    // 업데이트된 순서에 따라 priority 재설정
    reorderedItems.forEach((item, index) => {
      item.priority = index + 1;
    });
    
    // 로컬 상태와 부모 상태 모두 업데이트
    setLocalFiles(reorderedItems);
    onReorder(reorderedItems);
  }, [localFiles, onReorder]);

  // 파일 내용 편집 버튼 클릭 시 파일 내용을 서버에서 재로딩 (편집/미리보기 처리)
  const handleEditContent = async (file: FileWithPreview) => {
    try {
      const response = await axios.get(`${api}/get_file_content`, {
        params: { client_id: clientId, filename: file.name },
      });
      const fileType: 'pdf' | 'text' = response.data.type;
      setEditingContent({
        fileId: file.id,
        content: response.data.content,
        editable: fileType !== 'pdf',
        fileType: fileType,
      });
    } catch (error) {
      console.error("파일 내용을 불러오는 중 오류 발생", error);
      setEditingContent({
        fileId: file.id,
        content: "파일 내용을 불러오는데 실패했습니다.",
        editable: false,
        fileType: 'text',
      });
    }
  };

  // 편집된 파일 내용 저장 (JSON, log 파일만)
  const saveEditedContent = async () => {
    if (editingContent && editingContent.editable) {
      try {
        const targetFile = localFiles.find(f => f.id === editingContent.fileId);
        await axios.post(
          `${api}/update_file_content`,
          {
            client_id: clientId,
            filename: targetFile?.name,
            content: editingContent.content,
          },
          { headers: { 'Content-Type': 'application/json' } }
        );
        setEditingContent(null);
      } catch (error) {
        console.error("파일 내용을 저장하는 중 오류 발생", error);
      }
    } else {
      setEditingContent(null);
    }
  };

  // Description 입력 변경 시 디바운스 적용
  const handleDescChange = (file: FileWithPreview, value: string) => {
    setDescValues(prev => ({ ...prev, [file.id]: value }));
    if (timers.current[file.id]) clearTimeout(timers.current[file.id]);
    timers.current[file.id] = setTimeout(() => {
      const updatedFile = { ...file, description: value };
      handleUpdateFile(updatedFile);
    }, 300);
  };

  // 파일 제목 편집 완료 시 업데이트
  const finishEditingTitle = (file: FileWithPreview, newTitle: string) => {
    if (newTitle && newTitle !== file.name) {
      // 수정 전 제목을 oldName에 기록하여 업데이트
      handleUpdateFile({ ...file, name: newTitle, oldName: file.name });
    }
    setEditingTitle(null);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div className="draggable-list" {...provided.droppableProps} ref={provided.innerRef}>
            {localFiles.map((file, index) => (
              <Draggable key={file.id} draggableId={file.id} index={index}>
                {(provided) => (
                  <div 
                    className="draggable-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="file-header">
                      <div className="file-title">
                        {editingTitle && editingTitle.fileId === file.id ? (
                          <input 
                            value={editingTitle.title}
                            onChange={e => setEditingTitle({ fileId: file.id, title: e.target.value })}
                            onBlur={() => finishEditingTitle(file, editingTitle.title)}
                            onKeyDown={e => {
                              if(e.key === 'Enter'){
                                finishEditingTitle(file, editingTitle.title);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          <span>{file.name}</span>
                        )}
                      </div>
                      <div className="file-actions">
                        <button onClick={() => setEditingTitle({ fileId: file.id, title: file.name })}>
                          Edit Title
                        </button>
                        {editingContent && editingContent.fileId === file.id ? (
                          <button onClick={() => setEditingContent(null)}>Cancel Content Edit</button>
                        ) : (
                          <button onClick={() => handleEditContent(file)}>Edit Content</button>
                        )}
                        <button onClick={() => handleRemoveFile(file.id)}>Delete</button>
                      </div>
                    </div>
                    <div className="file-description">
                      <label>Description:</label>
                      <input 
                        type="text" 
                        value={descValues[file.id] !== undefined ? descValues[file.id] : file.description} 
                        onChange={e => handleDescChange(file, e.target.value)}
                      />
                    </div>
                    {editingContent && editingContent.fileId === file.id && (
                      <div className="file-edit-section">
                        <label>
                          File Content {editingContent.editable ? "(Editable)" : "(View Only)"}:
                        </label>
                        {editingContent.fileType === 'pdf' ? (
                          <iframe 
                            src={`data:application/pdf;base64,${editingContent.content}`}
                            title="PDF Preview"
                            style={{ width: '100%', height: '800px', border: 'none' }}
                          ></iframe>
                        ) : (
                          <textarea 
                            value={editingContent.content}
                            onChange={e => {
                              if (editingContent.editable) {
                                setEditingContent({ ...editingContent, content: e.target.value });
                              }
                            }}
                            readOnly={!editingContent.editable}
                            rows={6}
                            style={{ width: '100%' }}
                          />
                        )}
                        <div className="edit-buttons-policy">
                          {editingContent.editable && (
                            <button onClick={saveEditedContent}>Save</button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
