import React, { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../contexts/AuthContext";
import "./MaintCategories.css";

// API로 주고받는 데이터는 client_id와 동적 카테고리(각 카테고리는 string[] 값)
export interface MaintCategoriesData {
  id?: string;
  client_id?: string;
  // 동적 키: category name -> questions array
  [key: string]: any;
}

// 내부 편집을 위한 타입: 각 카테고리는 이름과 질문 배열을 갖습니다.
export interface CategoryItem {
  name: string;
  questions: string[];
}

const MaintCategories: React.FC<{ api: string }> = ({ api }) => {
  const { user } = useContext(AuthContext);
  // API에서 받아온 전체 데이터를 object 형태로 저장
  const [data, setData] = useState<MaintCategoriesData | null>(null);
  // 내부적으로 카테고리 배열 상태 관리
  const [categories, setCategories] = useState<CategoryItem[]>([]);
  // 현재 편집 중인 카테고리 인덱스 (하나만 동시에 편집)
  const [editingCategoryIndex, setEditingCategoryIndex] = useState<number | null>(null);
  // 신규 카테고리 추가를 위한 상태 (null이면 추가중이 아님)
  const [newCategory, setNewCategory] = useState<CategoryItem | null>(null);

  // API에서 데이터 조회
  const fetchData = useCallback(async () => {
    if (!user || !api) return;
    try {
      const res = await fetch(`${api}/maint_categories?client_id=${user.id}`);
      if (res.ok) {
        const json: MaintCategoriesData = await res.json();
        if (Object.keys(json).length === 0) {
          setData({ client_id: user.id });
          setCategories([]);
        } else {
          setData(json);
          // client_id와 id 빼고 나머지를 배열로 변환
          const cats: CategoryItem[] = [];
          for (const key in json) {
            if (key !== "client_id" && key !== "id") {
              cats.push({ name: key, questions: json[key] });
            }
          }
          setCategories(cats);
        }
      }
    } catch (error) {
      console.error("Error fetching maintenance categories:", error);
    }
  }, [api, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // API 업데이트: 전체 categories를 payload 형태(object)로 변환하여 저장
  const updateCategories = async (updatedCategories: CategoryItem[]) => {
    if (!user || !api) return;
    const payload: MaintCategoriesData = { client_id: user.id };
    updatedCategories.forEach(cat => {
      if (cat.name.trim() !== "") {
        payload[cat.name.trim()] = cat.questions.map(q => q.trim()).filter(q => q !== "");
      }
    });
    try {
      let response;
      if (data && data.id) {
        response = await fetch(`${api}/maint_categories/${data.id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });
      } else {
        response = await fetch(`${api}/maint_categories`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });
      }
      if (response.ok) {
        const json = await response.json();
        setData(json);
        // 다시 payload를 배열로 변환 (갱신)
        const cats: CategoryItem[] = [];
        for (const key in json) {
          if (key !== "client_id" && key !== "id") {
            cats.push({ name: key, questions: json[key] });
          }
        }
        setCategories(cats);
        // 편집 상태 초기화
        setEditingCategoryIndex(null);
        setNewCategory(null);
      }
    } catch (error) {
      console.error("Error updating maintenance categories:", error);
    }
  };

  // 개별 카테고리 수정 저장
  const handleSaveCategory = (index: number, updatedCat: CategoryItem) => {
    const updatedCategories = categories.map((cat, idx) => idx === index ? updatedCat : cat);
    setCategories(updatedCategories);
    updateCategories(updatedCategories);
  };

  // 개별 카테고리 삭제
  const handleDeleteCategory = (index: number) => {
    const updatedCategories = categories.filter((_, idx) => idx !== index);
    setCategories(updatedCategories);
    updateCategories(updatedCategories);
  };

  // 신규 카테고리 추가 처리
  const handleAddNewCategory = () => {
    setNewCategory({ name: "", questions: [] });
  };

  // 신규 카테고리 저장
  const handleSaveNewCategory = (cat: CategoryItem) => {
    const updatedCategories = [...categories, cat];
    setCategories(updatedCategories);
    updateCategories(updatedCategories);
  };

  const CategoryEditor: React.FC<{
    initialCat: CategoryItem;
    onSave: (cat: CategoryItem) => void;
    onCancel: () => void;
  }> = ({ initialCat, onSave, onCancel }) => {
    const [localCat, setLocalCat] = useState<CategoryItem>(initialCat);
  
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalCat({ ...localCat, name: e.target.value });
    };
  
    const handleQuestionChange = (qIndex: number, value: string) => {
      const updatedQuestions = [...localCat.questions];
      updatedQuestions[qIndex] = value;
      setLocalCat({ ...localCat, questions: updatedQuestions });
    };
  
    const handleAddQuestion = () => {
      setLocalCat({ ...localCat, questions: [...localCat.questions, ""] });
    };
  
    const handleRemoveQuestion = (qIndex: number) => {
      const updatedQuestions = localCat.questions.filter((_, idx) => idx !== qIndex);
      setLocalCat({ ...localCat, questions: updatedQuestions });
    };
  
    return (
      <div className="vendor-item">
        <div className="vendor-header">
          <input
            type="text"
            placeholder="Category Name"
            value={localCat.name}
            onChange={handleNameChange}
            className="category-name-input"
          />
          <div className="editor-buttons">
            <button type="button" className="form-button" onClick={onCancel}>
              Cancel
            </button>
            <button type="button" className="form-button" onClick={() => onSave(localCat)}>
              Save
            </button>
          </div>
        </div>
  
        {localCat.questions.map((q, qIndex) => (
          <div key={qIndex} className="question-item">
            <input
              type="text"
              placeholder="Enter question"
              value={q}
              onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
              className="form-input"
            />
            <button
              type="button"
              className="form-button delete"
              onClick={() => handleRemoveQuestion(qIndex)}
            >
              Remove
            </button>
          </div>
        ))}
  
        <div className="add-question-container">
          <button type="button" className="add-question-button" onClick={handleAddQuestion}>
            +
          </button>
        </div>
      </div>
    );
  };
  
  return (
    <div className="vendors-container">
      <div className="vendors-header">
        <div className="vendors-title">Maintenance Categories</div>
        <button className="form-button" onClick={handleAddNewCategory}>
          Add Category
        </button>
      </div>
      {newCategory && (
          <CategoryEditor
            initialCat={newCategory!}
            onSave={(cat) => handleSaveNewCategory(cat)}
            onCancel={() => setNewCategory(null)}
          />)}



      {/* 각 카테고리 항목 */}
      <ul className="vendor-list">
        {categories.map((cat, idx) => (
          <li key={idx} className="vendor-item">
            {editingCategoryIndex === idx ? (
              <CategoryEditor
                initialCat={cat}
                onSave={(updatedCat) => handleSaveCategory(idx, updatedCat)}
                onCancel={() => setEditingCategoryIndex(null)}
              />
            ) : (
              <div className="category-view">
                <div className="vendor-header">
                  <strong>{idx + 1}. {cat.name}</strong>
                  <div className="category-buttons">
                    <button className="form-button" onClick={() => setEditingCategoryIndex(idx)}>
                      Edit
                    </button>
                    <button className="form-button delete" onClick={() => handleDeleteCategory(idx)}>
                      Delete
                    </button>
                  </div>
                </div>
                {cat.questions.length > 0 && (
                  <ul>
                    {cat.questions.map((q, qIdx) => (
                      <li key={qIdx}>- {q}</li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MaintCategories;
