// src/components/Summary.tsx
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ResetSignalContext } from '../contexts/ResetSignalContext';
import './Summary_maint.css';

interface SummaryProps {
  api: string;
  selectedItem: string | null;
  client_id: string | null;
  listType: string;
}

interface SummaryItem {
  [key: string]: any;
}

interface DataListProps {
  data: any[];
  fields: { label: string; key: string }[];
  // 추가: responsibility_determined 필드에 대해 체크 아이콘 표시 여부 플래그 (필요시 각 항목에 대해 개별적으로 처리)
  showReadForResponsibility?: boolean;
}

const DataList: React.FC<DataListProps> = ({ data, fields, showReadForResponsibility }) => (
  <>
    {data.map((item, idx) => (
      <div key={idx} className="nested-item">
        {fields.map(({ label, key }) => (
          <p key={key} className="maint_section-content">
            <strong>{label}:</strong> {item[key] || '-'}
            {showReadForResponsibility && key === 'problem' && item['responsibility_determined'] && (
              <span style={{ color: 'green' }} className="read-check"> ✔</span>
            )}
          </p>
        ))}
      </div>
    ))}
  </>
);

interface ToggleSectionProps {
  title: string;
  children: React.ReactNode;
  read?: boolean;
}

const ToggleSection: React.FC<ToggleSectionProps> = ({ title, children, read = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="toggle-section">
      <div className="toggle-title" onClick={() => setIsOpen(!isOpen)}>
        {title}{' '}
        <div>
            {read && <span style={{ color: 'green' }} className="read-check">✔</span>}{' '}
          <span className="toggle-icon">{isOpen ? '▲' : '▼'}</span>
        </div>
      </div>
      {isOpen && <div className="toggle-content">{children}</div>}
    </div>
  );
};

interface MediaSectionProps {
  title: string;
  media: string[];
  type: 'image' | 'video';
}

const MediaSection: React.FC<MediaSectionProps> = ({ title, media, type }) => {
  if (!media || media.length === 0) return null;
  return (
    <div className="media-section">
      <h4 className="section-title">{title}</h4>
      {media.map((url, index) =>
        type === 'image' ? (
          <img
            key={index}
            src={url}
            alt={`${title} ${index + 1}`}
            style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }}
          />
        ) : (
          <video key={index} controls style={{ maxWidth: '100%', marginBottom: '10px' }}>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      )}
    </div>
  );
};

const renderField = (value: any, label: string) => (
  <div className="field">
    <p>
      <strong>{label}:</strong> {value || '-'}
    </p>
  </div>
);

const Summary_maint: React.FC<SummaryProps> = ({ api, selectedItem, client_id, listType }) => {
  const { resetSignal, clearReset } = useContext(ResetSignalContext);
  const [summaryData, setSummaryData] = useState<SummaryItem | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchSummary = async () => {
      if (!selectedItem) {
        setSummaryData(null);
        setLoading(false);
        return;
      }
      setLoading(true);
      setError('');
      try {
        const response = await axios.get(
          `${api}/get_interaction_metadata?client_id=${client_id}&email=${selectedItem}&listType=${listType}`
        );
        setSummaryData(Array.isArray(response.data) ? response.data[0] : response.data);
      } catch (err) {
        console.error('Error fetching summary:', err);
        setError('Failed to load summary.');
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [selectedItem, api, client_id, listType]);

  // resetSignal이 true이면 summaryData를 초기화하고 POST 요청 전송
  useEffect(() => {
    if (resetSignal) {
      setSummaryData(null);
      axios
        .post(`${api}/reset_interaction_metadata`, { client_id, email: selectedItem, listType })
        .then(response => {
          console.log('Reset posted:', response.data);
          clearReset();
        })
        .catch(err => {
          console.error('Error posting reset:', err);
        });
    }
  }, [resetSignal, api, client_id, selectedItem, listType, clearReset]);

  const responsibility_diagnosis_complete =
    summaryData?.maintenance?.interaction_metadata?.diagnosis &&
    Array.isArray(summaryData.maintenance.interaction_metadata.diagnosis)
      ? summaryData.maintenance.interaction_metadata.diagnosis.every((item: any) =>
          Object.prototype.hasOwnProperty.call(item, 'responsibility_determined')
        )
      : false;

  // if (loading) return <p className="loading-message">Loading summary...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="summary-content">
      {summaryData ? (
        <div className="height">
          <div className="section-title">Summary</div>
          <div className="maint_summary-item">
            {(() => {
              const { maintenance } = summaryData;
              return (
                <>
                  <p className="field">{maintenance.interaction_metadata.summary}</p>
                  {renderField(maintenance.address, 'Address')}
                  {renderField(maintenance.email, 'Tenant Email')}
                  {renderField(maintenance.phone_number, 'Tenant Phone')}
                  {renderField(
                    maintenance.interaction_metadata?.['send ticket to'],
                    'Send ticket to'
                  )}
                </>
              );
            })()}

            <div className="interaction-metadata">
              {/* Determine Validity: 체크 여부 emergency_diagnosis_complete */}
              {summaryData.maintenance.interaction_metadata.diagnose_emergency ? (
                <ToggleSection
                  title="Determine Validity"
                  read={!!summaryData.maintenance.interaction_metadata.emergency_diagnosis_complete}
                >
                  <DataList
                    data={summaryData.maintenance.interaction_metadata.diagnose_emergency}
                    fields={[
                      { label: 'problem', key: 'problem' },
                      { label: 'category', key: 'category' },
                      { label: 'reasoning', key: 'reasoning' }
                    ]}
                  />
                </ToggleSection>
              ) : (
                <ToggleSection
                title="Determine Validity"
              >
                <>  </>
              </ToggleSection>
              )}

              {/* Determine Vendor Type: 체크 여부 category_diagnosis_complete */}
              {summaryData.maintenance.interaction_metadata.diagnosis ? (
                <>
                  <ToggleSection
                    title="Determine Vendor Type"
                    read={!!summaryData.maintenance.interaction_metadata.category_diagnosis_complete}
                  >
                    <DataList
                      data={summaryData.maintenance.interaction_metadata.diagnosis}
                      fields={[
                        { label: 'problem', key: 'problem' },
                        { label: 'category', key: 'category' },
                        { label: 'reasoning', key: 'reasoning' }
                      ]}
                    />
                  </ToggleSection>

                  <ToggleSection
                    title="Determine Responsibility"
                    read={responsibility_diagnosis_complete}
                  >
                    <DataList
                      data={summaryData.maintenance.interaction_metadata.diagnosis}
                      fields={[
                        { label: 'problem', key: 'problem' },
                        { label: 'responsibility_determined', key: 'responsibility_determined' },
                        { label: 'responsibility_reasoning', key: 'responsibility_reasoning' }
                      ]}
                      showReadForResponsibility
                    />
                  </ToggleSection>

                  <ToggleSection
                    title="Diagnose">
                    <DataList
                      data={summaryData.maintenance.interaction_metadata.diagnosis}
                      fields={[
                        { label: 'problem', key: 'problem' },
                        { label: 'final_diagnosis', key: 'final_diagnosis' },
                        { label: 'current_diagnosis', key: 'current_diagnosis' }
                      ]}
                    />
                  </ToggleSection>
                </>
              ) : (
                <>
                  <ToggleSection
                    title="Determine Vendor Type"
                  >
                    <>  </>
                  </ToggleSection>

                  <ToggleSection
                    title="Determine Responsibility"
                  >
                    <>  </>
                  </ToggleSection>

                  <ToggleSection
                    title="Diagnose">
                    <>  </>
                  </ToggleSection>
                </>
              )

              }
            </div>

            <div className="media-container">
              <MediaSection
                title="Images"
                media={Array.isArray(summaryData.images) ? summaryData.images : []}
                type="image"
              />
              <MediaSection
                title="Videos"
                media={Array.isArray(summaryData.videos) ? summaryData.videos : []}
                type="video"
              />
            </div>
          </div>
        </div>
      ) : (
        <p className="no-summaries-message">
          No summary available for the selected client.
        </p>
      )}
    </div>
  );
};

export default Summary_maint;
