// src/components/Summary.tsx
import React, { useState, useEffect } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import './Summary.css'; // Import the CSS file for Neumorphic styles

interface SummaryProps {
  selectedClient: string | null;
  clientID: string | null;
}

interface SummaryItem {
  phone_number: string;
  email: string;
  summary: string
  channel: string;
  original_interested_property_address: string;
  current_cross_sell_address: string;
  qualification: string;
  criteria_summary: string;
  original_interested_property_k_id : string;
}

const Summary: React.FC<SummaryProps> = ({ selectedClient, clientID }) => {
  const [summaries, setSummaries] = useState<SummaryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showRightPanel, setShowRightPanel] = useState<boolean>(false);

  // Fetch summaries when selectedClient changes
  useEffect(() => {
    const fetchSummaries = async () => {
      if (!selectedClient) {
        setSummaries([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError('');

      try {
        const response = await axios.get<SummaryItem[]>(
          `https://flaskserver.kindredpm.ai/get_interaction_metadata?client_id=${clientID}&email=${encodeURIComponent(selectedClient)}`
        );

        // Check if the response data is an array or a single object
        if (Array.isArray(response.data)) {
          setSummaries(response.data);
        } else {
          setSummaries([response.data]); // Convert single object to array
        }
      } catch (err) {
        console.error('Error fetching summaries:', err);
        setError('Failed to load summaries.');
      } finally {
        setLoading(false);
      }
    };

    fetchSummaries();
  }, [selectedClient]);

  return (
    <>
      <div className="summary-content">
        {/* Loading State */}
        {loading && <p className="loading-message">Loading summaries...</p>}

        {/* Error State */}
        {error && <p className="error-message">{error}</p>}

        {/* Summaries Content */}
        {!loading && !error && summaries.length > 0 && (
          <div>
            {summaries.map((summary) => (
              <div key={summary.original_interested_property_k_id} className="summary-item">
                <h4 className="section-title">Client Information</h4>
                <p className="section-content"><strong>Phone:</strong> {summary.phone_number}</p>
                <p className="section-content"><strong>Email:</strong> {summary.email}</p>
                <p className="section-content"><strong>Summary:</strong> {summary.summary}</p>
                <p className="section-content"><strong>Channel:</strong> {summary.channel}</p>
                <p className="section-content"><strong>Interested_Property:</strong> {summary.original_interested_property_address}</p>
                <p className="section-content"><strong>Other Potential Property:</strong> {summary.current_cross_sell_address}</p>
                <p className="section-content"><strong>Qualification:</strong> {summary.qualification}</p>
                <p className="section-content"><strong>Preferences:</strong> {summary.criteria_summary}</p>
              </div>
            ))}
          </div>
        )}

        {/* No Summaries Available */}
        {!loading && !error && summaries.length === 0 && (
          <p className="no-summaries-message">No summaries available for the selected client.</p>
        )}
      </div>
    </>
  );
};

export default Summary;
