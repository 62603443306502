// src/FileUploader.tsx
import axios from 'axios';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useDropzone, FileRejection } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import './FileUploader.css';
import DraggableList from "../components/DraggableList";

export interface FileWithPreview {
  id: string;
  file: File;
  name: string;            // 현재(수정된) 제목
  oldName?: string;        // 수정 전 제목 (옵션)
  size: string;
  preview: string;
  description: string;
  priority: number;
  content?: string;
}

interface FileUploaderProps {
  api: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({ api }) => {
  const { user } = useContext(AuthContext);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

// 초기 문서 목록 로드
  useEffect(() => {
    const fetchDocuments = async () => {
      if (!user) return;
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${api}/get_documents_list?client_id=${user.id}`);
        const documents = response.data;
        const newFiles: FileWithPreview[] = Object.entries(documents).map(([name, info]: [string, any]) => ({
          id: uuidv4(),
          file: new File([], name), // placeholder
          name,
          size: '0 KB',
          preview: '',
          content: info.content || '',
          description: info.description || 'Enter description',
          priority: info.priority || 1,
        })).sort((a, b) => a.priority - b.priority);
        setFiles(newFiles);
      } catch (err) {
        console.error('Error fetching documents:', err);
        setError('An error occurred while fetching documents.');
      } finally {
        setLoading(false);
      }
    };
    fetchDocuments();
  }, [api, user]);



  // 파일 업로드 및 문서 목록 업데이트 (단일 API 호출)
  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setError('');
    setSuccess(false);

    if (fileRejections.length > 0) {
      const rejection = fileRejections[0];
      if (rejection.errors.some(e => e.code === 'file-invalid-type')) {
        setError('Unsupported file type. Only .txt, .log, and .pdf files can be uploaded.');
      }
      if (rejection.errors.some(e => e.code === 'file-too-large')) {
        setError('File size is too large. Maximum size is 1MB.');
      }
      return;
    }

    for (const file of acceptedFiles) {
      if (files.some(f => f.name === file.name)) {
        setError(`A file named ${file.name} already exists.`);
        continue;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('client_id', user ? user.id : '');
      // 파일 제목이 수정되어 있다면 new_title 필드 추가
      if ('newTitle' in file && (file as any).newTitle) {
        formData.append('new_title', String((file as any).newTitle));
      }
      try {
        setUploading(true);
        const response = await axios.post(`${api}/save_policy`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        // 백엔드에서 업데이트된 문서 목록을 받아 상태를 갱신
        const updatedDocuments = response.data.documents;
        const updatedFiles: FileWithPreview[] = Object.entries(updatedDocuments).map(([name, info]: [string, any]) => ({
          id: uuidv4(),
          file: name === file.name ? file : new File([], name),
          name,
          size: name === file.name ? (file.size / 1024).toFixed(2) + ' KB' : '0 KB',
          preview: '',
          content: info.content || '',
          description: info.description || 'Enter description',
          priority: info.priority || 1,
        })).sort((a, b) => a.priority - b.priority);
        setFiles(updatedFiles);
        setSuccess(true);
      } catch (err) {
        console.error(`File upload failed: ${file.name}`, err);
        setError(`Failed to upload file: ${file.name}`);
      } finally {
        setUploading(false);
      }
    }
  }, [api, user, files]);

  // 파일 제거 시: 로컬 상태 업데이트 후 문서 목록 API 호출
  const handleRemoveFile = useCallback(async (id: string) => {
    const removedFile = files.find(file => file.id === id);
    if (!removedFile) return;
    const updatedFiles = files
      .filter(file => file.id !== id)
      .map(file => file.priority > removedFile.priority ? { ...file, priority: file.priority - 1 } : file);
    
    try {
      await axios.post(`${api}/update_documents_list`, {
        client_id: user ? user.id : '',
        documents: updatedFiles.map(file => ({
          name: file.name,
          description: file.description,
          priority: file.priority
        }))
      }, { headers: { 'Content-Type': 'application/json' } });
      setFiles(updatedFiles);
    } catch (err) {
      console.error('Failed to update documents after removal:', err);
      setError('Failed to update documents.');
    }
  }, [api, user, files]);

  // 드래그앤드롭으로 순서 변경 시
  const handleReorder = useCallback(async (reorderedItems: FileWithPreview[]) => {
    const updatedItems = reorderedItems.map((item, index) => ({ ...item, priority: index + 1 }));
    try {
      await axios.post(`${api}/update_documents_list`, {
        client_id: user ? user.id : '',
        documents: updatedItems.map(file => ({
          name: file.name,
          description: file.description,
          priority: file.priority
        }))
      }, { headers: { 'Content-Type': 'application/json' } });
      setFiles(updatedItems);
    } catch (err) {
      console.error('Failed to update documents after reordering:', err);
      setError('Failed to update documents.');
    }
  }, [api, user]);

  // 파일 내용/제목 변경 후 업데이트 API 호출
  const handleUpdateFile = useCallback(async (updatedFile: FileWithPreview) => {
    const updatedFiles = files.map(file => 
      file.id === updatedFile.id 
        ? { ...updatedFile, oldName: file.oldName || file.name }  // 기존 이름 기록 (UI에서 전달한 경우 oldName이 있을 것)
        : file
    ).sort((a, b) => a.priority - b.priority);
  
    try {
      await axios.post(`${api}/update_documents_list`, {
        client_id: user ? user.id : '',
        documents: updatedFiles.map(file => ({
          old_name: file.oldName && file.oldName !== file.name ? file.oldName : undefined,
          name: file.name,
          description: file.description,
          priority: file.priority,
          content: file.content || ''
        }))
      }, { headers: { 'Content-Type': 'application/json' } });
      setFiles(updatedFiles);
    } catch (err) {
      console.error('Failed to update document:', err);
      setError('Failed to update document.');
    }
  }, [api, user, files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt', '.log'],
      'application/pdf': ['.pdf']
    },
    maxSize: 1024 * 1024,
    multiple: true,
  });

  // 메모리 누수 방지를 위한 cleanup
  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <div className="responsive-flex-column">
      <div {...getRootProps({ className: 'dropzone' })} className="dropzone">
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drag files here...</p>
        ) : (
          <p>Drag and drop (.txt, .pdf, .log) files here or click to upload.</p>
        )}
      </div>
      {files.length > 0 ? (
        <DraggableList 
          files={files} 
          onReorder={handleReorder} 
          handleRemoveFile={handleRemoveFile} 
          handleUpdateFile={handleUpdateFile}
          api={api}
          clientId={user ? user.id : ''}
        />
      ): (
        <p className="success" >No files uploaded yet.</p>
      )}
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {uploading && <p>Uploading...</p>}
      {success && <p className="success">File uploaded successfully!</p>}
    </div>
  );
};

export default FileUploader;
