import React, { useState, useEffect, useCallback, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import "./Vendors.css";

export interface Vendor {
  id?: string;
  client_id?: string; // client_id 필드 추가
  name: string;
  description?: string;
  specialty?: string;
  phone_number?: string;
  email?: string;
}

interface VendorFormProps {
  vendor?: Vendor;
  onSave: (vendor: Vendor) => void;
  onCancel: () => void;
}

const VendorForm: React.FC<VendorFormProps> = ({ vendor, onSave, onCancel }) => {
  const [formData, setFormData] = useState<Vendor>(
    vendor || { name: "", description: "", specialty: "", phone_number: "", email: "" }
  );

  useEffect(() => {
    setFormData(vendor || { name: "", description: "", specialty: "", phone_number: "", email: "" });
  }, [vendor]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="vendor-form">
      <input
        className="form-input"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        className="form-input"
        name="description"
        placeholder="Description"
        value={formData.description}
        onChange={handleChange}
      />
      <input
        className="form-input"
        name="specialty"
        placeholder="Specialty"
        value={formData.specialty}
        onChange={handleChange}
      />
      <input
        className="form-input"
        name="phone_number"
        placeholder="Phone Number"
        value={formData.phone_number}
        onChange={handleChange}
      />
      <input
        className="form-input"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
      />
      <div className="form-buttons">
        <button type="submit" className="form-button">
          Save
        </button>
        <button type="button" className="form-button" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};

interface VendorsProps {
  api: string;
}

const Vendors: React.FC<VendorsProps> = ({ api }) => {
  const { user } = useContext(AuthContext);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [editingVendorId, setEditingVendorId] = useState<string | null>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  // 현재 사용자 id를 쿼리 파라미터로 전달하여 해당 client_id에 속하는 데이터를 가져옵니다.
  const fetchVendors = useCallback(async () => {
    if (!user || !api) return;
    try {
      const res = await fetch(`${api}/vendors?client_id=${user.id}`);
      const data: Vendor[] = await res.json();
      setVendors(data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  }, [api]);

  useEffect(() => {
    fetchVendors();
  }, [fetchVendors]);

  const handleDelete = async (id: string) => {
    if (!user || !api) return;
    try {
      await fetch(`${api}/vendors/${id}?client_id=${user.id}`, {
        method: "DELETE",
      });
      fetchVendors();
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  // 저장(추가/수정) 시 payload에 client_id를 포함합니다.
  const handleSave = async (vendorData: Vendor, id?: string) => {
    if (!user || !api) return;
    try {
      const payload = { ...vendorData, client_id: user.id };
      if (id) {
        // 수정: PUT 요청
        await fetch(`${api}/vendors/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      } else {
        // 추가: POST 요청
        await fetch(`${api}/vendors`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      }
      setEditingVendorId(null);
      setIsAdding(false);
      fetchVendors();
    } catch (error) {
      console.error("Save error:", error);
    }
  };

  return (
    <div className="vendors-container">
      <div className="vendors-header">
        <div className="vendors-title">Vendors</div>
        <button className="form-button" onClick={() => setIsAdding(true)}>
          Add Vendor
        </button>
      </div>

      {isAdding && (
        <VendorForm onSave={(data) => handleSave(data)} onCancel={() => setIsAdding(false)} />
      )}

      <ul className="vendor-list">
        {vendors.map((vendor, idx) => (
          <li key={vendor.id} className="vendor-item">
            {editingVendorId === vendor.id ? (
              <VendorForm
                vendor={vendor}
                onSave={(data) => handleSave(data, vendor.id)}
                onCancel={() => setEditingVendorId(null)}
              />
            ) : (
              <div>
                <div className="vendor-header">
                  <strong>{idx+1}. {vendor.name}</strong>
                  <div>
                    <button className="form-button" onClick={() => setEditingVendorId(vendor.id!)}>
                      Edit
                    </button>
                    <button className="form-button delete" onClick={() => vendor.id && handleDelete(vendor.id)}>
                      Delete
                    </button>
                  </div>
                </div>                
                <p><strong>- Description :</strong> {vendor.description}</p>
                <p><strong>- Specialty :</strong> {vendor.specialty}</p>
                <p><strong>- Phone :</strong> {vendor.phone_number}</p>
                <p><strong>- Email :</strong> {vendor.email}</p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Vendors;
