// src/pages/NewLeads.tsx
import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { UnReadCountContext } from '../contexts/UnReadCountContext';
import Listing from '../components/Listing_clients';
import Listing_maint from '../components/Listing_clients_maint';
import Chats from '../components/Chats'
import Chats_maint from '../components/Chats_maint'
import Summary from '../components/Summary';
import Summary_maint from '../components/Summary_maint';
import { useSocket } from '../contexts/SocketContext';
import './NewLeads.css';

const ClientsNumLimit = 1000;

// export interface ItemCRM {
//   _id: string;
//   email: string;
//   isRead: boolean;
//   isStar?: boolean;
//   isCheck?: boolean;
//   timestamp?: string;
//   details?: string;
// }

// export interface ItemWO {
//   _id: string;
//   WO_ID: string;
//   address: string;
//   email: string;
//   isRead: boolean;
//   isStar?: boolean;
//   isCheck?: boolean;
//   timestamp?: string;
//   details?: string;
// }

interface Option {
  isStar: boolean;
  isUnRead: boolean;
  retrieve: string;
  limit: number;
}

export interface BaseItem {
  email: string;
  isRead: boolean;
  isStar?: boolean;
  isCheck?: boolean;
  timestamp: string;
  details?: string;
  WO_ID: string;
  address: string;
}

export interface ItemCRM extends BaseItem {
  // CRMItem은 BaseItem의 속성만 사용
}

export interface ItemWO extends BaseItem {
  WO_ID: string;
  address: string;
}

// 조건부 타입: PureType에 따라 ItemCRM 또는 ItemWO 결정
type ItemType<T extends "crm" | "crm_flag" | "WO" | "WO_flag"> = T extends "crm" | "crm_flag"
  ? ItemCRM
  : T extends "WO" | "WO_flag"
  ? ItemWO
  : never;

// APIProps에 제네릭 T를 사용하여 listType의 값에 따라 타입이 결정되도록 함
interface APIProps<T extends "crm" | "crm_flag" | "WO" | "WO_flag"> {
  api: string;
  listType: T;
}

const NewLeads_maint = <T extends "crm" | "crm_flag" | "WO" | "WO_flag">({ api, listType }: APIProps<T>) => {
  type Item = ItemType<T>;
  
  // listType에 따라 초기 selectedItem 객체 분기 처리
  const initialItem: Item = ((): Item => {
      return {
        WO_ID: '',
        address: '',
        email: '',
        isRead: false,
        isStar: false,
        isCheck: false,
        timestamp: '',
        details: '',
      } as Item;
    }
  )();
  
  const { user } = useContext(AuthContext);
  const { setUnReadCount, setUnReadCountFlag, setUnReadMaintCount, setUnReadCountMaintFlag} = useContext(UnReadCountContext);
  const socket = useSocket();

  // 타입별로 차이나는 변수들을 객체로 관리
  const config = {
    crm: {
      listEvent: 'get_crm_list',
      setUnRead: setUnReadCount,
    },
    crm_flag: {
      listEvent: 'get_crm_list',
      setUnRead: setUnReadCountFlag,
    },
    WO: {
      listEvent: 'get_crm_list',
      setUnRead: setUnReadMaintCount,
    },
    WO_flag: {
      listEvent: 'get_crm_list',
      setUnRead: setUnReadCountMaintFlag,
    }
  };

  // 현재 선택된 설정 객체
  const currentConfig = config[listType];

  const [items, setItems] = useState<Item[]>([]);

  const [selectedItem, setSelectedItem] = useState<Item>(initialItem);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [option, setOption] = useState<Option>({
    isStar: false,
    isUnRead: false,
    retrieve: "",
    limit: ClientsNumLimit,
  });
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const isInitialResponse = useRef(true); // 초기 응답 여부 확인

  useEffect(() => {
    setSelectedItem(initialItem);
  }, [listType]);

  useEffect(() => {
    if (!user) return;

    interface CRMResponse {
      error?: string;
      data?: Item[];
      listType: string;
    }

    const handleCRMResponse = (response: CRMResponse) => {
      if (response.error) {
        setError(response.error);
      } else if (response.data) {
        if (response.listType === listType) {
          setItems(response.data);
        }
        // 초기 응답이면 unread count를 증가시키지 않음
        // if (!isInitialResponse.current) {
        //   currentConfig.setUnRead(prev => prev + 1);
        // } else {
        //   isInitialResponse.current = false; // 초기 응답 이후부터는 증가하도록 함
        // }
      }
      setLoading(false);
    };

    const handleConnectionError = (err: any) => {
      setError('WebSocket connection error.');
      setLoading(false);
      console.error('Connection Error:', err);
    };

    setLoading(true);
    setError(null);

    socket.emit(currentConfig.listEvent, {
      client_id: user.id,
      option: option,
      listType: listType,
    });

    socket.on('crm_list_response', handleCRMResponse);
    socket.on('connect_error', handleConnectionError);

    return () => {
      socket.off('crm_list_response', handleCRMResponse);
      socket.off('connect_error', handleConnectionError);
    };
  }, [user, socket, option, listType]);

  // 이메일을 받아 해당 아이템을 읽음 처리 & selectedItem으로 지정
  const handleSelectedItem = (WO_ID: string) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.WO_ID === WO_ID ? { ...item, isRead: true } : item
      )
    );
    const foundItem = items.find(item => item.WO_ID === WO_ID);
    if (foundItem) {
      setSelectedItem({ ...foundItem, isRead: true });
    }

    if (user) {
      socket.emit('update_is_read', {
        client_id: user.id,
        item: WO_ID,
        listType: listType,
      });
    }
  };

  // 이메일 & star값을 받아 해당 아이템의 별표 토글
  const handleStarItem = (WO_ID: string, star: boolean) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.WO_ID === WO_ID ? { ...item, isStar: star } : item
      )
    );
    if (socket && user) {
      socket.emit('update_is_star', {
        client_id: user.id,
        item: WO_ID,
        isStar: star,
        listType: listType,
      });
    }
  };

  // 체크된 항목들 삭제
  const handleDeleteChecked = (checkedItems: string[]) => {
    if (!socket || !user) return;
    const previouslyUnReadCount = items.filter(item => checkedItems.includes(item.WO_ID) && !item.isRead).length;
    if (window.confirm('Do you want to delete the selected items?')) {
      socket.emit('update_is_delete', {
        client_id: user.id,
        checkedItems: checkedItems,
        listType: listType,
      });
      setItems(prev => prev.filter(item => !checkedItems.includes(item.WO_ID)));
    }
    currentConfig.setUnRead(prev => prev - previouslyUnReadCount);
    setCheckedItems([]);
  };

  // 체크된 항목들 읽음 처리
  const handleChangeRead = (checkedItems: string[]) => {
    if (!socket || !user) return;
    const previouslyUnReadCount = items.filter(item => checkedItems.includes(item.WO_ID) && !item.isRead).length;
    socket.emit('update_is_onRead', {
      client_id: user.id,
      checkedItems: checkedItems,
      listType: listType,
    });
    setItems(prevItems =>
      prevItems.map(item =>
        checkedItems.includes(item.WO_ID) ? { ...item, isRead: true } : item
      )
    );
    currentConfig.setUnRead(prev => prev - previouslyUnReadCount);
    setCheckedItems([]);
  };

  // 체크된 항목들 안 읽음 처리
  const handleChangeUnRead = (checkedItems: string[]) => {
    if (!socket || !user) return;
    const previouslyReadCount = items.filter(item => checkedItems.includes(item.WO_ID) && item.isRead).length;
    socket.emit('update_is_unRead', {
      client_id: user.id,
      checkedItems: checkedItems,
      listType: listType,
    });
    setItems(prevItems =>
      prevItems.map(item =>
        checkedItems.includes(item.WO_ID) ? { ...item, isRead: false } : item
      )
    );
    currentConfig.setUnRead(prev => prev + previouslyReadCount);
    setCheckedItems([]);
  };

  return (
    <div className="responsive-flex">
      {user && (
        <>
          <Listing_maint
            items={items}
            loading={loading}
            error={error}
            selectedItem={selectedItem}
            handleSelectedItem={handleSelectedItem}
            handleStarItem={handleStarItem}
            onDeleteChecked={handleDeleteChecked}
            onChangeRead={handleChangeRead}
            onChangeUnRead={handleChangeUnRead}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            option={option}
            setOption={setOption}
            setUnReadCount={currentConfig.setUnRead}
          />
          <Chats_maint
            api={api}
            selectedItem={selectedItem.WO_ID}
            client_id={user.id}
            isEditModeParents={false}
            listType={listType}
          />
          <Summary_maint
            api={api}
            selectedItem={selectedItem.WO_ID}
            client_id={user.id}
            listType={listType}
          />
        </>
        )
      }
    </div>
  );
};

export default NewLeads_maint;
