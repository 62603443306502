// src/components/Summary.tsx
import React, { useState, useEffect } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import './Summary.css'; // Import the CSS file for Neumorphic styles
import { NavLink } from 'react-router-dom';

interface SummaryProps {
  api: string;
  selectedItem: string | null;
  client_id: string | null;
}

interface SummaryItem {
  phone_number?: string;
  email: string;
  summary: string
  channel: string;
  original_interested_property_address: string;
  current_cross_sell_address: string;
  criteria_summary: string;
  original_interested_property_k_id : string;
  reasoning? : string
}

const Summary: React.FC<SummaryProps> = ({ api, selectedItem, client_id }) => {
  const [summaries, setSummaries] = useState<SummaryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showRightPanel, setShowRightPanel] = useState<boolean>(false);

  // Fetch summaries when selectedItem changes
  useEffect(() => {
    const fetchSummaries = async () => {
      if (!selectedItem) {
        setSummaries([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError('');

      try {
        const response = await axios.get<SummaryItem[]>(
          `${api}/get_interaction_metadata?client_id=${client_id}&email=${selectedItem}`
        );

        // Check if the response data is an array or a single object
        if (Array.isArray(response.data)) {
          setSummaries(response.data);
        } else {
          setSummaries([response.data]); // Convert single object to array
        }
      } catch (err) {
        console.error('Error fetching summaries:', err);
        setError('Failed to load summaries.');
      } finally {
        setLoading(false);
      }
    };

    fetchSummaries();
  }, [selectedItem]);

  const handleToProperty = () => {
    setShowRightPanel(true);
  }

  return (
    <>
      <div className="summary-content">
        {/* Loading State */}
        {loading && <p className="loading-message">Loading summaries...</p>}

        {/* Error State */}
        {error && <p className="error-message">{error}</p>}

        {showRightPanel && (
          <NavLink
          to="/properties">
          </NavLink>
        )}
        

        {/* Summaries Content */}
        {!loading && !error && summaries.length > 0 && (
          <div>
            {summaries.map((summary) => (summary &&
              <div key={summary.original_interested_property_k_id} className="summary-item">
                <h4 className="section-title">Client Information</h4>
                {summary.phone_number && (<p className="section-content"><strong>Phone:</strong> {summary.phone_number}</p>)}
                {summary.email && (<p className="section-content"><strong>Email:</strong> {summary.email}</p>)}
                {summary.summary && (<p className="section-content"><strong>Summary:</strong> {summary.summary}</p>)}
                {summary.channel && (<p className="section-content"><strong>Channel:</strong> {summary.channel}</p>)}
                {/* {summary.original_interested_property_address && (<p className="section-content" onClick={handleToProperty}><strong>Interested_Property:</strong> {summary.original_interested_property_address}</p>)} */}
                {summary.original_interested_property_address && (
                  <p>
                    <NavLink
                    to={`/properties?address=${encodeURIComponent(summary.original_interested_property_address)}`}
                    className="section-content property-link"
                    >
                    <strong>Interested_Property:</strong> {summary.original_interested_property_address}
                    </NavLink>
                  </p>
                )}
                {summary.current_cross_sell_address && (
                  <p>
                    <NavLink
                    to={`/properties?address=${encodeURIComponent(summary.current_cross_sell_address)}`}
                    className="section-content property-link"
                    >
                    <strong>Other Potential Property:</strong> {summary.current_cross_sell_address}
                    </NavLink>
                  </p>
                )}
                {summary.criteria_summary && (<p className="section-content"><strong>Preferences:</strong> {summary.criteria_summary}</p>)}
                {summary.reasoning && (<p className="section-content-reasoning"><strong>Reasoning:</strong> {summary.reasoning}</p>)}
              </div>
            ))}
          </div>
        )}

        {/* No Summaries Available */}
        {!loading && !error && summaries.length === 0 && (
          <p className="no-summaries-message">No summaries available for the selected client.</p>
        )}
      </div>
    </>
  );
};

export default Summary;