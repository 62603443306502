// src/components/Properties.tsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import '../components/Listing.css'; // Import the Neumorphic styles from Listing.css
import Listing from '../components/Listing';
import VendorDetail from '../components/VendorDetail.jsx';

// interface Property  {
//   Kindred_ID: number;
//   address: string;
//   description: string;
//   "date available": string; // Keeping as string for date input
//   "base monthly rent": string; // Number
//   "square feet": number; // Number
//   rooms: number; // Number
//   baths: number; // Number
//   parking: object;
//   amenities: string[],
//   customAmenities: string,
//   restrictions: object;
//   neighborhood: object;
//   "mandatory monthly fees": {},
//   "optional monthly fees": {},
//   "optional deposits": {},
//   "mandatory one-time fees": {},
//   utilities: object[];
//   customUtilities: string;
// };

// src/types/Vendor.ts
export interface Vendor {
  address: string;
  description: string;
  email: string;
  notes: string;
  "phone number": string;
  specialty: string[];
}

interface Vendors {
  [vendorName: string]: Vendor;
}

const Vendors: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [vendors, setVendors] = useState<Vendors>({});
  const [selectedVendor, setSelectedVendor] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const API = user ? `https://flaskserver.kindredpm.ai/preferred_vendors?client_id=${user.id}`: '' ;

  function handleSelectedVendors(vendor: string) {
    setSelectedVendor(vendor);
  }

  // Fetch properties from the API when the component mounts
  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get<Vendors>(API);
        setVendors(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError('Failed to load clients.');
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  return (
    <div className="responsive-flex">
      {/* Client List */}
      <Listing
        clients={Object.keys(vendors)}
        loading={loading}
        error={error}
        selectedClient={selectedVendor}
        handleSelectedClient={handleSelectedVendors}
        />

      {/* Chat History */}
      {/* {properties.filter(item => item.address === selectedProperty)}; */}
      {/* <VendorDetail selectedProperty={vendors[selectedVendors]} /> */}
      {selectedVendor && vendors[selectedVendor] && user? (
        <VendorDetail selectedProperty={vendors[selectedVendor]} selectedVendor={selectedVendor} clientID={user.id} />
      ) : (
        <div className="no-selection">
          {loading ? "Loading..." : error ? error : "Select a vendor to see details."}
        </div>
      )}
      {/* Summary */}
      {/* <Summary selectedClient={selectedClient}/> */}
    </div>
  );
};

export default Vendors;
