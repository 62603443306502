// src/components/Listing.tsx
import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import './Listing.css'; // Import the CSS file for Neumorphic styles

interface ListingProps {
  items: string[];
  title: string;
  loading: boolean;
  error: string | null;
  selectedItem: string | null;
  handleSelectedItem: (item: string) => void;
}

const Listing: React.FC<ListingProps> = ({ items, title, loading, error, selectedItem, handleSelectedItem }) => {
  // **배열을 복사한 후 정렬**
  const sortedItems = React.useMemo(() => {
    return [...items].sort();
  }, [items]);

  return (
    <div className="listing-container">
      <div className="listing-header" style={{ cursor: 'pointer' }}>
        <h2 className="listing-title">{title}</h2>
        {/* {isOpen ? (
          <ChevronUpIcon className="chevron-icon" />
        ) : (
          <ChevronDownIcon className="chevron-icon" />
        )} */}
      </div>

      <div className="listing-content">
        {error ? (
          <div className="error">{error}</div>
        ) : (
          <ul className="client-list">
            {sortedItems.map((item) => (
            <li
              key={item}
              className={`client-item ${selectedItem === item ? 'selected' : ''}`}
              onClick={() => handleSelectedItem(item)}
            >
              <UserCircleIcon className="client-icon" />
              <span className="client-name">{item}</span>
            </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};


export default Listing;
