// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 컨테이너 및 기본 폰트 */
.vendors-container {
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* 헤더 스타일 */
.vendors-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.vendor-header {
  display: flex;
  justify-content: space-between;
}
.vendors-title {
  font-size: 24px;
  font-weight: bold;
}

/* 리스트 스타일 */
.vendor-list {
  list-style: none;
  padding: 0;
}

.vendor-item {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
}

/* 폼 스타일 */
.vendor-form {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 15px 0;
  border-radius: 4px;
}

.form-input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-buttons {
  margin-top: 10px;
}

.form-button {
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Vendors.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;AAChC;;AAEA,WAAW;AACX;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA,YAAY;AACZ;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,UAAU;AACV;EACE,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* 컨테이너 및 기본 폰트 */\n.vendors-container {\n  width: 90%;\n  max-width: 800px;\n  margin: 20px auto;\n  padding: 20px;\n  font-family: Arial, sans-serif;\n}\n\n/* 헤더 스타일 */\n.vendors-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.vendor-header {\n  display: flex;\n  justify-content: space-between;\n}\n.vendors-title {\n  font-size: 24px;\n  font-weight: bold;\n}\n\n/* 리스트 스타일 */\n.vendor-list {\n  list-style: none;\n  padding: 0;\n}\n\n.vendor-item {\n  border: 1px solid #ddd;\n  padding: 15px;\n  margin-bottom: 15px;\n  border-radius: 4px;\n}\n\n/* 폼 스타일 */\n.vendor-form {\n  border: 1px solid #ccc;\n  padding: 15px;\n  margin: 15px 0;\n  border-radius: 4px;\n}\n\n.form-input {\n  width: 100%;\n  padding: 8px;\n  margin: 5px 0;\n  border: 1px solid #aaa;\n  border-radius: 4px;\n  box-sizing: border-box;\n}\n\n.form-buttons {\n  margin-top: 10px;\n}\n\n.form-button {\n  padding: 8px 12px;\n  margin-right: 10px;\n  border: none;\n  border-radius: 4px;\n  background-color: #007bff;\n  color: white;\n  cursor: pointer;\n}\n\n.form-button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
