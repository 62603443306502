// src/components/Summary.tsx
import React, { useState, useEffect, useContext } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { ResetSignalContext } from '../contexts/ResetSignalContext';
import './Summary.css';
import { NavLink } from 'react-router-dom';

interface SummaryProps {
  api: string;
  selectedItem: string | null;
  client_id: string | null;
  listType: string;
}

interface SummaryItem {
  phone_number?: string;
  email: string;
  summary: string;
  channel: string;
  original_interested_property_address: string;
  current_cross_sell_address: string;
  qualification: string;
  criteria_summary: string;
  original_interested_property_k_id: string;
  reasoning?: string;
}

const Summary: React.FC<SummaryProps> = ({ api, selectedItem, client_id, listType }) => {
  const {resetSignal, clearReset} = useContext(ResetSignalContext);

  const [summaries, setSummaries] = useState<SummaryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // selectedItem 변경 시 기존 fetch
  useEffect(() => {
    const fetchSummaries = async () => {
      if (!selectedItem) {
        setSummaries([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError('');

      try {
        const response = await axios.get<SummaryItem[]>(
          `${api}/get_interaction_metadata?client_id=${client_id}&email=${selectedItem}&listType=${listType}`
        );

        if (Array.isArray(response.data)) {
          setSummaries(response.data);
        } else {
          setSummaries([response.data]);
        }
      } catch (err) {
        console.error('Error fetching summaries:', err);
        setError('Failed to load summaries.');
      } finally {
        setLoading(false);
      }
    };

    fetchSummaries();
  }, [selectedItem, api, client_id, listType]);

  // resetSignal을 감지하여 summaries를 초기화하고 POST 요청을 보냄
  useEffect(() => {
    if (resetSignal) {
      setSummaries([]);
      axios.post(`${api}/reset_interaction_metadata`, {
        client_id,
        email: selectedItem,
        listType
      })
      .then(response => {
        console.log("Reset posted:", response.data);
        clearReset();
      })
      .catch(error => {
        console.error("Error posting reset:", error);
      });
    }
  }, [resetSignal, api, client_id, selectedItem, listType]);

  return (
    <div className="summary-content">
      {loading && <p className="loading-message">Loading summaries...</p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && summaries.length > 0 && (
        <div className="height">
          
          {summaries.map((summary) => (summary &&
            <div className="height">
              <h4 className="section-title">Client Information</h4>
              <div key={summary.original_interested_property_k_id} className="summary-item">
                {summary.phone_number && (<p className="section-content"><strong>Phone:</strong> {summary.phone_number}</p>)}
                {summary.email && (<p className="section-content"><strong>Email:</strong> {summary.email}</p>)}
                {summary.summary && (<p className="section-content"><strong>Summary:</strong> {summary.summary}</p>)}
                {summary.channel && (<p className="section-content"><strong>Channel:</strong> {summary.channel}</p>)}
                {summary.original_interested_property_address && (
                  <p>
                    <NavLink
                      to={`/properties?address=${encodeURIComponent(summary.original_interested_property_address)}`}
                      className="section-content property-link"
                    >
                      <strong>Interested_Property:</strong> {summary.original_interested_property_address}
                    </NavLink>
                  </p>
                )}
                {summary.current_cross_sell_address && (
                  <p>
                    <NavLink
                      to={`/properties?address=${encodeURIComponent(summary.current_cross_sell_address)}`}
                      className="section-content property-link"
                    >
                      <strong>Other Potential Property:</strong> {summary.current_cross_sell_address}
                    </NavLink>
                  </p>
                )}
                {summary.qualification && (<p className="section-content"><strong>Qualification:</strong> {summary.qualification}</p>)}
                {summary.criteria_summary && (<p className="section-content"><strong>Preferences:</strong> {summary.criteria_summary}</p>)}
                {summary.reasoning && (<p className="section-content-reasoning"><strong>Reasoning:</strong> {summary.reasoning}</p>)}
              </div>
            </div>
          ))}
        </div>
      )}
      {!loading && !error && summaries.length === 0 && (
        <p className="no-summaries-message">No summaries available for the selected client.</p>
      )}
    </div>
  );
};

export default Summary;
