// src/components/Chats.tsx
import React, { useState, useEffect, useRef, useContext } from "react";
import { io, Socket } from "socket.io-client";
import { AuthContext } from "../contexts/AuthContext";
import { UnReadCountContext } from '../contexts/UnReadCountContext';
import { ResetSignalContext } from '../contexts/ResetSignalContext';
import { useSocket } from '../contexts/SocketContext';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Chats.css"; 
import ChatList from "./ChatList"; // 새로운 컴포넌트 임포트


interface ChatProps {
  api: string;
  selectedItem: string;
  client_id: string | null;
  isEditModeParents: boolean;
  listType: string;
}

interface ChatMessage {
  timestamp: string;
  channel: string;
  details: string;
  notes: string;
  reasoning?: string;
}

const Chats_maint: React.FC<ChatProps> = ({ api, selectedItem, client_id, isEditModeParents, listType }) => {
  const { isAdmin } = useContext(AuthContext);
  const { setIsTrigger} = useContext(UnReadCountContext);
  const { triggerReset } = useContext(ResetSignalContext);
  const socket = useSocket(); // 소켓 인스턴스 사용

  const [chats, setChats] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState<boolean>(isEditModeParents);
  const [testMessage, setTestMessage] = useState<string>('');
  const [initialData, setInitialData] = useState<ChatMessage[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<string>("sms"); 
  const [expandedMessages, setExpandedMessages] = useState<Set<number>>(new Set());
  const [chatListKey, setChatListKey] = useState<number>(0);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // 하단으로 자동 스크롤
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setChats([]); 
  }, [listType]);
    
  useEffect(() => {
    setTestMessage('');
    setIsEditMode(false);
  }, [selectedItem]);

  useEffect(() => {
    scrollToBottom();
  }, [chats, isEditMode]);

  const handleResponse = (data: ChatMessage[]) => {
    setChats([...data]);
    // key 값을 업데이트하여 ChatList가 강제로 재마운트됨
    setChatListKey(prev => prev + 1);
    setLoading(false);
    scrollToBottom();
  };  

  /**
   * 메시지 전송 함수
   */
  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const newChat: ChatMessage = {
      timestamp: new Date().toISOString(),
      channel: selectedChannel, 
      details: newMessage,
      notes: "sent", // "sent" → agent가 보낸 메시지
    };
    const updatedChats = [...chats, newChat];
    setChats(updatedChats);
    setNewMessage("");
    toast.info("Please wait a minute as KindredPM comes up with an answer", {
      autoClose: 5000, // 3초 후 자동 닫힘
    });

    try {
      const payload = {
        WO_ID: selectedItem,
        message: newMessage,
        channel: selectedChannel,
        client_id: client_id
      };

      // 서버에 메시지 전송
      await axios.post(
        `${api}/send_msg_via_frontend`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // 성공 시 콘솔 찍어보기 (필요에 따라 로직 추가)
      console.log("Message sent successfully.");
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message.");
    } finally {
      setNewMessage("");
    }
  };
    

  /**
   * 서버로 수정된 chats 배열을 전송
   */
  const postUpdatedChats = async (newChats: ChatMessage[]) => {
    if (!api || !client_id || !selectedItem) return;

    try {
      // 수정된 chats 배열을 서버로 전송
      await axios.post(
        // API 엔드포인트
        `${api}/post_maintenance_interactions?client_id=${client_id}&WO_ID=${selectedItem}`,
        newChats,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Chats updated successfully on the server.");
    } catch (error) {
      console.error("Error updating chats:", error);
      setError("Failed to update chats.");
    }
  };

  /**
   * 메시지 펼침 토글
   */
  const toggleMessage = (index: number) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  /**
   * 채널 선택
   */
  const handleChannelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedChannel(e.target.value);
  };

  /**
   * Chat 초기화 기능
   */
  const handleReset = () => {
    const filteredChats = initialData.filter(
      chat => chat.channel === "new lead" || chat.channel === "new work order"
    );
    setChats(filteredChats);
    setInitialData(filteredChats);

    // 초기화 후 서버에 업데이트된 chats 전송
    postUpdatedChats(filteredChats);
    triggerReset();
  }

  // src/components/Chats.tsx
  // (생략된 코드 생략)

  const handleGetLogging = async () => {
    try {
      const response = await axios.get(
        `${api}/get_logging?client_id=${client_id}&WO_ID=${selectedItem}&listType=${listType}`
      );
      console.log("Logging:", response.data);

      // logging 데이터를 JSON 문자열로 변환 (가독성을 위해 들여쓰기 포함)
      const fileData = JSON.stringify(response.data, null, 2);
      // Blob 객체 생성 (MIME 타입은 JSON)
      const blob = new Blob([fileData], { type: 'application/json' });
      // Blob URL 생성
      const url = window.URL.createObjectURL(blob);
      // 다운로드를 위한 a 태그 생성
      const link = document.createElement('a');
      link.href = url;
      // 저장될 파일명 지정
      link.download = 'loggingData.json';
      // a 태그 클릭하여 다운로드 트리거
      link.click();
      // 사용 후 URL 객체 해제
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error fetching logging:", err);
      setError("Failed to load logging.");
    }
  };


  /**
   * Chat 삭제 기능
   */
  const handleDeleteChat = (index: number) => {
    const updatedChats = [...chats];
    updatedChats.splice(index, 1);
    setChats(updatedChats);
    setInitialData(updatedChats);

    // 삭제 후 서버에 업데이트된 chats 전송
    postUpdatedChats(updatedChats);
  };

  /**
   * Chat details 수정 기능
   */
  const handleUpdateChatDetails = (index: number, newDetails: string) => {
    const updatedChats = [...chats];
    updatedChats[index].details = newDetails;
    setChats(updatedChats);

    // 수정 후 서버에 업데이트된 chats 전송
    postUpdatedChats(updatedChats);
  };

  /**
   * 선택된 클라이언트의 채팅 히스토리 가져오기
   */
  useEffect(() => {
    console.log("selectedItem:", selectedItem);
    if (!selectedItem) {
      setChats([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    interface ChatResponse {
      error?: string;
      data?: ChatMessage[];
      selected_id?: string;
    }

    // 서버로부터 "get_interactions" 이벤트 수신
    const handleResponse = (response: ChatResponse) => {
      console.log("get_interactions:", response);
      if (response.error) {
        setError(response.error);
      } else if (response.data){
        if (response.selected_id === selectedItem) {
          const data = response.data;
          setInitialData(data);
          setChats(data);
          setLoading(false);
          scrollToBottom();
        }
        setIsTrigger(true);
      }
    }
      
    // 서버에 "get_interactions" 이벤트 전송 (이벤트에 필요한 데이터 포함)
    socket.emit("get_interactions", { email: selectedItem, client_id: client_id, listType: listType });

    socket.on("get_interactions", handleResponse);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      socket.off("get_interactions", handleResponse);
    };
  }, [selectedItem, client_id, socket]);

  useEffect(() => {
    if (!isEditMode) {
      setChats(initialData)
      return;
    }

    if (isEditMode) {
      if (testMessage === "Customer") {
        const emptyMessage: ChatMessage = {
          timestamp: new Date().toISOString(),
          channel: "new lead",
          details: "Edit Message",
          notes: "received",
          reasoning: "Empty Message",
        };
        setChats([...initialData, emptyMessage]);
      } else if (testMessage === "KindredPM") {
        const emptyMessage: ChatMessage = {
          timestamp: new Date().toISOString(),
          channel: "email",
          details: "Edit Message",
          notes: "sent",
          reasoning: "Empty Message",
        };
        setChats([...initialData, emptyMessage]);
      }
    }
  }, [isEditMode, testMessage]);

  const handleRender = async () => {
    console.log("handleRender");
    try {
      setInitialData(chats);
      const payload = {
        WO_ID: selectedItem,
        client_id: client_id
      };

      // 서버에 메시지 전송
      await axios.post(
        `${api}/rerender_maintenance_conversation`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.info("Please wait a minute as KindredPM comes up with an answer", {
        autoClose: 5000, // 3초 후 자동 닫힘
      });
    }
    catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message.");
    }
  }
  
  return (
    <div className="chat-wrapper">
      {selectedItem ? (
        <div className="chats-content">
          {/* Chat Title */}
          <h2 className="chats-title">
            WO #{selectedItem}'s Chat History
            <div className="edit-mode">
              {isEditMode ? (
                <div className="edit-buttons">
                  <div className="button-row">
                    <button className="send-button red" onClick={() => { setIsEditMode(false); setTestMessage('') }}>Cancel</button>
                    <button className="send-button" onClick={handleRender}>Render</button>
                    <button className="send-button" onClick={handleReset}>Reset</button>
                    {isAdmin && <button className="send-button" onClick={handleGetLogging}>Logging</button>}
                    <ToastContainer />
                  </div>
                  <div className="button-row">
                    <button className="send-button" onClick={() => setTestMessage("Customer")}>Customer</button>
                    <button className="send-button" onClick={() => setTestMessage("KindredPM")}>KindredPM</button>
                  </div>
                </div>
              ) : (
                <button className="send-button" onClick={() => setIsEditMode(true)}>Edit</button> 
              )}
              
            </div>
          </h2>


          {/* Chat Messages */}
          <div className="chats-messages">
            {loading && <p className="loading-message">Loading chats...</p>}
            {error && <p className="error-message">{error}</p>}
            {!loading && !error && chats.length > 0 ? (
              <ChatList
                chats={chats}
                expandedMessages={expandedMessages}
                onToggleMessage={toggleMessage}
                onDeleteChat={handleDeleteChat}
                onUpdateChatDetails={handleUpdateChatDetails}
                isEditMode={isEditMode}
                selectedItem={selectedItem}
                client_id={client_id}
              />
            ) : (
              !loading && !error && <p className="no-chats-message">No chats available for the selected client.</p>
            )}
            <div ref={messagesEndRef} />
          </div>


          {/* Chat Input Area */}
          <div className="chat-input-container">
            <div className="channel-selector">
              <label htmlFor="channel-select" className="channel-label">Channel:</label>
              <select
                id="channel-select"
                className="channel-select"
                value={selectedChannel}
                onChange={handleChannelChange}
                aria-label="Select communication channel"
              >
                <option value="sms">sms</option>
                <option value="email">email</option>
                {/* Add more channels as needed */}
              </select>
            </div>
            
            <input
              type="text"
              className="chat-input"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              aria-label="Type your message"
            />
            <button
              className="send-button"
              onClick={handleSendMessage}
              aria-label="Send message"
            >
              Send
            </button>
            <ToastContainer />
          </div>
        </div>
      ) : (
        <div className="no-client-selected">
          <p>Select a client to view chat history.</p>
        </div>
      )}
    </div>
  );
};

export default Chats_maint;