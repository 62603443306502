// src/components/PropertyDetail.tsx
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import { useSearchParams } from 'react-router-dom';

import './PropertyDetail.css'; // PropertyDetail.css 임포트

interface PropertyDetailProps {
  api: string;
  dir: string;
  client_id: string;
  Kindred_ID: string;
}

const PropertyDetail: React.FC<PropertyDetailProps> = ({ api, dir, client_id, Kindred_ID }) => {
  const [content, setContent] = useState<any>(null); // 가능한 구체적인 타입 정의 권장
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>('');

  useEffect(() => {
    // API에서 데이터 가져오기
    const fetchProperty = async () => {
      try {
        const response = await axios.get(`${api}/${dir}?client_id=${client_id}&Kindred_ID=${Kindred_ID}`);
        // 상태에 데이터 설정
        setContent(response.data);
        setError('');
      } catch (error) {
        console.error('Error fetching property:', error);
        setError('Error fetching property. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [api, client_id, Kindred_ID]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="property-container">
      <div className="property-detail">
        {content &&
          Object.entries(content).map(([key, value]) => (
            <div key={key} className="property-detail-item">
              <strong>{key}:</strong>
              {typeof value === 'object' && value !== null ? (
                Array.isArray(value) ? (
                  <span className="value">{value.join(', ')}</span>
                ) : (
                  Object.entries(value).map(([_key, _value]) => (
                    <li key={_key} className="property-detail-item-item">
                      {_key}: {typeof _value === 'object' && _value !== null ? (
                      JSON.stringify(_value["details"], null, 2)
                      ) : _value?.toString()}
                    </li>
                  ))
                )
              ) : (
                <span className="value">{String(value)}</span>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
  
export default PropertyDetail;