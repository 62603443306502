// src/components/ChatList.tsx
import React from 'react';
import ChatItem from './ChatItem';

interface ChatMessage {
  timestamp: string;
  channel: string;
  details: string;
  notes: string;
  reasoning?: string;
}

interface ChatListProps {
  chats: ChatMessage[];
  expandedMessages: Set<number>;
  onToggleMessage: (index: number) => void;
  onDeleteChat: (index: number) => void;
  onUpdateChatDetails: (index: number, newDetails: string) => void;
  isEditMode: boolean;
  selectedItem: string;
  client_id: string | null;
}

const ChatList: React.FC<ChatListProps> = ({
  chats,
  expandedMessages,
  onToggleMessage,
  onDeleteChat,
  onUpdateChatDetails,
  isEditMode,
  selectedItem,
  client_id

}) => {
  return (
    <>
      {chats.map((chat, index) => {
      if (!chat.details) return null;
      if (typeof chat.details !== 'string') chat.details= JSON.stringify(chat.details);
      return (
        <ChatItem
        chat={chat}
        prevChannel={index > 0 ? chats[index - 1].channel : 'email'}
        index={index}
        isExpanded={expandedMessages.has(index)}
        onToggleMessage={onToggleMessage}
        onDeleteChat={onDeleteChat}
        onUpdateChatDetails={onUpdateChatDetails}
        isEditMode={isEditMode}
        selectedItem={selectedItem}
        client_id={client_id}
        />
      );
      })}
    </>
  );
};

export default ChatList;