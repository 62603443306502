import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import logo from '../assets/KindredAI_logo_mini.png';
import './Chats.css';

console.log('ChatItem component loaded');

interface ChatMessage {
  timestamp: string;
  channel: string;
  details: string | null; // Notice we allow details to be null or string
  notes: string;
  reasoning?: string;
}

interface ChatItemProps {
  chat: ChatMessage;
  index: number;
  isExpanded: boolean;
  onToggleMessage: (index: number) => void;
  onDeleteChat: (index: number) => void;
  onUpdateChatDetails: (index: number, newDetails: string) => void;
  isEditMode: boolean;
}

const ChatItem: React.FC<ChatItemProps> = ({
  chat,
  index,
  isExpanded,
  onToggleMessage,
  onDeleteChat,
  onUpdateChatDetails,
  isEditMode
}) => {
  console.log(`Rendering ChatItem #${index}`, { isExpanded, isEditMode, chat });

  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(chat.details || '');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const detailsRef = useRef<HTMLParagraphElement | null>(null);
  const [detailsWidth, setDetailsWidth] = useState<number | null>(null);

  const getSender = (notes: string): "agent" | "client" => {
    const sender = notes.toLowerCase() === "sent" ? "agent" : "client";
    console.log(`Determined sender for notes "${notes}":`, sender);
    return sender;
  };

  const sender = getSender(chat.notes);
  console.log('Current sender:', sender);

  useEffect(() => {
    console.log('Textarea effect running', { isEditing, editedDetails });
    if (textareaRef.current) {
      console.log('Adjusting textarea height');
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [editedDetails, isEditing]);

  const handleEdit = () => {
    console.log('Edit button clicked', { isEditing, detailsRef: detailsRef.current });
    if (!isEditing) {
      if (detailsRef.current) {
        console.log('Measuring details width:', detailsRef.current.offsetWidth);
        setDetailsWidth(detailsRef.current.offsetWidth);
      } else {
        console.warn('Details ref is null when entering edit mode');
      }
    } else {
      console.log('Saving edited details');
      onUpdateChatDetails(index, editedDetails);
    }
    setIsEditing(!isEditing);
  };

  console.log('Rendering chat item with reasoning:', chat.reasoning);

  return (
    <div className="chat-item" key={`${chat.timestamp}-${index}`}>
      {sender === "agent" ? (
        <img
          src={logo}
          alt="KindredPM Logo"
          className="client-icon agent"
        />
      ) : (
        <UserCircleIcon className="client-icon client" />
      )}
      <div
        className={`chat-bubble ${sender === "agent" ? "agent" : "client"}`}
        onClick={() => {
          console.log('Chat bubble clicked', { sender, index });
          sender === "agent" && onToggleMessage(index);
        }}
        style={sender === "agent" ? { cursor: 'pointer' } : {}}
      >
        <div className="chat-content">
          {!isEditing ? (
            <p className={`chat-details ${sender}`} ref={detailsRef}>
              {(
                console.log('Rendering non-editable details'),
                // Fix: Use (chat.details || '') to safely handle null or undefined
                (chat.details || '').split(/(https:\/\/\S+)/g).map((part, i) => {
                  console.log('Processing details part:', part);
                  return part.startsWith('https://') ? (
                    <a
                      key={i}
                      href={part}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'blue', textDecoration: 'underline' }}
                      onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'none')}
                      onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                    >
                      {part}
                    </a>
                  ) : (
                    part
                  );
                })
              )}
            </p>
          ) : (
            <textarea
              className="edit-input"
              value={editedDetails}
              onChange={(e) => {
                console.log('Textarea content changed:', e.target.value);
                setEditedDetails(e.target.value);
              }}
              ref={textareaRef}
              style={{ width: detailsWidth ? `${detailsWidth}px` : 'auto' }}
            />
          )}

          {sender === "agent" && isExpanded && chat.reasoning && (
            <p className="chat-reasoning">
              {(console.log('Rendering reasoning section'), chat.reasoning)}
            </p>
          )}
          <span className="chat-timestamp">
            {(console.log('Rendering timestamp'), chat.channel)}{' '}
            {new Date(chat.timestamp).toLocaleString()}
          </span>
        </div>
      </div>
      <div className={`chat-actions ${sender === "agent" ? "agent" : "client"}`}>
        {isEditMode && (
          <>
            <button
              className="chat-delete-button"
              onClick={() => {
                console.log('Delete button clicked');
                onDeleteChat(index);
              }}
            >
              Delete
            </button>
            <button className="chat-edit-button" onClick={handleEdit}>
              {isEditing ? "Save" : "Edit"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatItem;
