// src/components/Listing_NewLeads.tsx
import React, { useRef, useState, useEffect } from 'react';
import {
  ChevronDownIcon,
  TrashIcon,
  EnvelopeOpenIcon,
  EnvelopeIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';
import './Listing.css';
import ClientItem from './Listing_clientItem';

const ClientsPerPage = 20;

export interface Item {
  email: string;
  isRead: boolean;
  isStar?: boolean;
  isCheck?: boolean;
  timestamp: string;
  details?: string;
}

interface Option {
  isStar: boolean;
  isUnRead: boolean;
  retrieve: string;
  limit: number;
}

interface ListingProps {
  items: Item[];
  loading: boolean;
  error: string | null;
  selectedItem: Item;
  handleSelectedItem: (email: string) => void;            // idx -> email
  handleStarItem: (email: string, star: boolean) => void; // idx -> email
  onDeleteChecked: (checkedItems: string[]) => void;
  onChangeRead: (checkedItems: string[]) => void;
  onChangeUnRead: (checkedItems: string[]) => void;
  checkedItems: string[];
  setCheckedItems: React.Dispatch<React.SetStateAction<string[]>>;
  option: Option;
  setOption: React.Dispatch<React.SetStateAction<Option>>;
  setUnReadCount: React.Dispatch<React.SetStateAction<number>>;
}

const Listing: React.FC<ListingProps> = ({
  items,
  loading,
  error,
  selectedItem,
  handleSelectedItem,
  handleStarItem,
  onDeleteChecked,
  onChangeRead,
  onChangeUnRead,
  checkedItems,
  setCheckedItems,
  option,
  setOption,
  setUnReadCount
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
  const [toggleOpen, setToggleOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showRetrieveInput, setShowRetrieveInput] = useState(true);

  useEffect(() => {
    const newTotalPages = items.length > 0 ? Math.ceil(items.length / ClientsPerPage) : 1;
    setTotalPages(newTotalPages);
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }
  }, [items.length]);

  // 현재 페이지 모든 항목이 체크되어 있는가?
  const isPageAllChecked =
    items.length > 0 && items.every(item => checkedItems.includes(item.email));

  // 개별 체크박스 토글 (email 기준)
  const handleCheckItem = (email: string, willCheck: boolean) => {
    setCheckedItems(prev => {
      if (willCheck) {
        // 중복 추가 방지
        if (!prev.includes(email)) {
          return [...prev, email];
        }
        return prev;
      } else {
        // 체크 해제
        return prev.filter(e => e !== email);
      }
    });
  };

  // 현재 페이지 전체선택/해제
  const handleToggleTotalCheckbox = () => {
    if (isPageAllChecked) {
      // 전체 해제
      const pageEmails = items.map(item => item.email);
      setCheckedItems(prev => prev.filter(email => !pageEmails.includes(email)));
    } else {
      // 전체 체크
      const pageEmails = items.map(item => item.email);
      setCheckedItems(prev => {
        const newSet = new Set(prev);
        pageEmails.forEach(email => newSet.add(email));
        return Array.from(newSet);
      });
    }
  };

  const handleShowStar = () => {
    setOption(prev => ({ ...prev, isStar: !prev.isStar }));
    setToggleOpen(false);
  }

  const handleShowUnread = () => {
    setOption(prev => ({ ...prev, isUnRead: !prev.isUnRead }));
    setToggleOpen(false);
  }

  // Delete / Read / Unread 버튼
  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteChecked(checkedItems);
    setCheckedItems([]);
  };

  const handleRead = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChangeRead(checkedItems);
    setCheckedItems([]);
  };

  const handleUnRead = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChangeUnRead(checkedItems);
    setCheckedItems([]);
  };

  // 페이지네이션
  const handlePageChange = (page: any) => {
    if (page < 1 || page > totalPages || page === currentPage) return;
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pages;
  };

  return (
    <div className="listing-container">

      <div
        className="listing-header"
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }}
      >
        <input
          type="checkbox"
          checked={isPageAllChecked}
          onChange={handleToggleTotalCheckbox}
          className="nav-icon"
        />
        
        <div className="toggle-container overlay">
          <button className="nav-icon" onClick={() => setToggleOpen(!toggleOpen)}>
            <ChevronDownIcon className={`toggle-icon ${toggleOpen ? 'open' : ''}`} />
          </button>
          {toggleOpen && (
            <div className="toggle-dropdown overlay-dropdown">
              <button onClick={handleShowStar} className="toggle-item">
                {option.isStar ? 'Off Starred' : 'Starred'}
              </button>
              <button onClick={handleShowUnread} className="toggle-item">
                {option.isUnRead ? 'Off Unread' : 'Unread'}
              </button>
              {/* <button onClick={handleRetrieve} className="toggle-item">
                {option.retrieve ? 'All' : 'Retrieve'}
              </button> */}
            </div>
          )}
        </div>
        
        <TrashIcon className="nav-icon" onClick={handleDelete} />
        <EnvelopeOpenIcon className="nav-icon" onClick={handleRead} />
        <EnvelopeIcon className="nav-icon" onClick={handleUnRead} />

        <h2 className="listing-title">Clients</h2>
      </div>
      {showRetrieveInput && (
        <div className="retrieve-input">
          <input
            type="text"
            value={option.retrieve}
            onChange={(e) =>
              setOption(prev => ({ ...prev, retrieve: e.target.value }))
            }
            placeholder="Enter your search term"
          />
        </div>
       )}
      <div className="listing-content">
        {loading ? (
          <div className="loading">Loading items...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <ul className="client-list">
            {items.slice((currentPage - 1) * ClientsPerPage, currentPage * ClientsPerPage).map(item => (
              <ClientItem
                item={item}
                selectedItem={selectedItem}
                onClick={handleSelectedItem}     // => (email: string)
                onStar={handleStarItem}          // => (email: string, star: boolean)
                isChecked={checkedItems.includes(item.email)}
                onCheck={handleCheckItem}        // => (email: string, boolean)
                setUnReadCount={setUnReadCount}
              />
            ))}
          </ul>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="pagination-container">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || isLoadingPage}
        >
          <ChevronLeftIcon className="icon" />
          Prev
        </button>

        <div className="pagination-numbers">
          {getPageNumbers().map((page, index) => (
            <React.Fragment key={index}>
              {page === '...' ? (
                <span className="pagination-ellipsis">...</span>
              ) : (
                <button
                  className={`pagination-number ${page === currentPage ? 'active' : ''}`}
                  onClick={() => handlePageChange(page)}
                  disabled={page === currentPage || isLoadingPage}
                >
                  {page}
                </button>
              )}
            </React.Fragment>
          ))}
        </div>

        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages || isLoadingPage}
        >
          Next
          <ChevronRightIcon className="icon" />
        </button>
      </div>
    </div>
  );
};

export default Listing;
