// src/App.tsx
import React from 'react';
import {useContext} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import NewLeads from './pages/NewLeads';
import Properties from './pages/Properties';
import ManualReview from './pages/ManualReview';
import Settings from './pages/Settings';
// import Navbar from './pages/Navbar';
// import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import RepairTickets from './pages/RepairTickets';
import Vendors from './pages/Vendors';
import PropertiesAndTenants from './pages/PropertiesAndTenants';
import Logout from './pages/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedLayout from './components/ProtectedLayout';
import houseImage from './assets/house2.jpg'; // Ensure the correct path to your image file
import './Neumorphism.css'; // Import Neumorphic styles

const App: React.FC = () => {

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Login />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route element={<ProtectedLayout />}>
          <Route path="/new-leads" element={<NewLeads />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/manual-review" element={<ManualReview />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/repair-tickets" element={<RepairTickets />} />
          <Route path="/vendors" element={<Vendors />} />
          <Route path="/propertiesAndTenants" element={<PropertiesAndTenants />} />
          <Route path="/logout" element={<Logout />} />
          {/* Redirect any unknown routes to /new-leads */}
          <Route path="*" element={<Navigate to="/new-leads" replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;