// React Component with mouse reactive and click effects
import React, { useRef, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Register.css';

interface MouseReactiveButtonProps {
  selectedItem: string;
}

const MouseReactiveButton: React.FC<MouseReactiveButtonProps> = ({selectedItem}) => {
  const { user, setUser } = useContext(AuthContext);

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const buttonGlowRef = useRef<HTMLSpanElement>(null);
  const [isPressed, setIsPressed] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const api: string = process.env.REACT_APP_FLASKSERVER || '';

  // Add click animation callback
  const handleDuplicate = async () => {
    toast.info("Please wait a minute as KindredPM comes up with an answer", {
      autoClose: 3000, // 3초 후 자동 닫힘
    });

    try {
      const response = await axios.post(`${api}/duplicate_client2Admin`, {
        client_id: selectedItem,
      });
      if (response.status === 200) {
        setSuccess('Client duplicateed to admin successfully!');
        setError(null);
      } else {
        setError('Failed to duplicate client to admin.');
        setSuccess(null);
      }
    } catch (err: any) {
      console.error('Error duplicateing client to admin:', err);
      setError('Error duplicateing client to admin.');
      setSuccess(null);
    }
  };


  return (
    <div>
      <button type="submit" className="duplicate-button" onClick={handleDuplicate}>
        Duplicate
      </button>
      <ToastContainer />
    </div>
  );
};

export default MouseReactiveButton;