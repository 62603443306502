import React, { createContext, useState } from 'react';

interface ResetSignalContextType {
  resetSignal: boolean;
  triggerReset: () => void;
  clearReset: () => void;
}

export const ResetSignalContext = createContext<ResetSignalContextType>({
  resetSignal: false,
  triggerReset: () => {},
  clearReset: () => {},
});

export const ResetSignalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [resetSignal, setResetSignal] = useState<boolean>(false);

  const triggerReset = () => {
    setResetSignal(true);
  };

  const clearReset = () => {
    setResetSignal(false);
  };

  return (
    <ResetSignalContext.Provider value={{ resetSignal, triggerReset, clearReset }}>
      {children}
    </ResetSignalContext.Provider>
  );
};
